import React from 'react'

import Section from '../../../shared/section'
import VideoSettingsForm from '../../../../../containers/settings/my-account/account-details/video-settings/video-settings'
import { TESTERS } from '../../../../../lib/tester-classnames'
import { useFeatureFlag } from '../../../../../hooks/useFeatureFlag'

function VideoSettings() {
  const showAutoplayTrailer = useFeatureFlag(
    'respect_turnOffTrailerAutoplay',
    false
  )

  return (
    showAutoplayTrailer && (
      <Section
        title="Video Settings"
        testerClassName={TESTERS.ACCOUNT.VIDEO_SETTINGS}
      >
        <VideoSettingsForm />
      </Section>
    )
  )
}

export default VideoSettings
