
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {
  compose, withState, withHandlers, pure
} from 'recompose'

import Input from './input'
import styles from './password-input.css'

function PasswordInput({
  label,
  readonly,
  readonlyTextOverwrite,
  type,
  btnText,
  onClick,
  disabled,
  wrapperClassName,
  ...props
}) {
  return (
    <div className={classNames(styles.showPasswordWrapper, wrapperClassName)}>
      <Input
        {...props}
        label={label}
        type={type}
        disabled={disabled}
        readonly={readonly}
        readonlyTextOverwrite={readonlyTextOverwrite}
        checkClass={styles.checkPassword}
        exclamationClass={classNames({
          [styles.exclamationPassword]: true,
          [styles.noLabel]: !label
        })}
      />
      {
        !readonly && !disabled && (
          <button
            type="button"
            className={classNames({
              [styles.showPassword]: true,
              [styles.noLabel]: !label
            })}
            onClick={onClick}
          >
            {btnText}
          </button>
        )
      }
    </div>
  )
}

PasswordInput.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  type: PropTypes.string.isRequired,
  btnText: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  readonly: PropTypes.bool,
  readonlyTextOverwrite: PropTypes.string,
  wrapperClassName: PropTypes.string
}

PasswordInput.defaultProps = {
  label: '',
  value: '',
  disabled: false,
  readonly: false,
  readonlyTextOverwrite: '',
  wrapperClassName: ''
}

const enhance = compose(
  withState('type', 'setType', 'password'),
  withState('btnText', 'setText', 'show'),
  withHandlers({
    onClick: ({ setType, setText }) => (event) => {
      if (event.target.innerHTML === 'show') { // show password
        setType('text')
        setText('hide')
      } else {
        setType('password')
        setText('show')
      }
    }
  })
)

export default enhance((pure)(PasswordInput))
